

















































































import { Component, Vue } from 'vue-property-decorator';
import location from '@/views/flow-list/components/location.vue';
import LeftTree from '@/views/front-frame/components/left-tree.vue';
// import FlowApplicationTypeTree from '@/views/flow-list/components/flow-application-type-tree.vue';
import HomePage from '@/api/home/homePage';
import DateUtil from 'global-ui/packages/utils/DateUtil';
import { FlowOperationList } from '@/models/flow/FlowConst';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'HomeMoreFlowEnd',
  components: {
    location,
    LeftTree,
    // FlowApplicationTypeTree
  },
  filters: {
    // 格式化类型显示
    formatSubmitType: function (submitType) {
      if (!submitType) {
        submitType === '-1';
      }
      let filter = FlowOperationList.filter(item => item.id === submitType);
      if (filter.length > 0) {
        return this.$t(filter[0].name);
      }
    }
  }
})
export default class HomeMoreFlowEnd extends Vue {
  homePage: HomePage = new HomePage();

  // 搜索表单
  searchData: any = {
    // 搜索标题
    title: '',
    // 应用类型id
    applicationIds: []
  };

  // 表格数据
  tableList: any[] = [];
  // 表格加载状态
  tableLoading: boolean = false;

  // 分页参数
  pageIndex: number = 1;
  pageSize: number = 10;
  pageTotal: number = 0;

  created() {
    this.getTableData();
  }

  //点击左侧树
  handleNodeClick(sonSearchParams) {
    if (sonSearchParams.applicationIds) {
      this.searchData.applicationIds = sonSearchParams.applicationIds;
      this.searchData.processCode = sonSearchParams.processCode;
    }
    // if (val.type === '1') {
    //   this.searchData.applicationIds = [val.id];
    // } else if (val.type === '0') {
    //   // 获取应用类型树下 type 为 1 的数据
    //   let getTypeTreeTypeForOne = function (dataList: any[]) {
    //     let res: any[] = [];
    //     dataList.forEach((item: any) => {
    //       if (item.type === '1') {
    //         res.push(item);
    //       }
    //       if (item.children && item.children.length > 0) {
    //         res.push(...getTypeTreeTypeForOne(item.children));
    //       }
    //     });
    //     return res;
    //   };
    //   this.searchData.applicationIds = getTypeTreeTypeForOne(val.children).map(item => item.id);
    // } else {
    //   this.searchData.applicationIds=[val.id]
    // }
    this.pageIndex = 1;
    this.getTableData();
  }

  /**
   * 跳转至流程
   * @param row
   */
  approve(row) {
    if (row) {
      // let token = TokenUtil.getToken();
      // let url = process.env.VUE_APP_SERVER_CODE + 'task/redirect/end';
      // let params = '?id=' + id + '&token=' + token;
      // window.location.href = url + params;
      // this.$router.push({ path: '/flow/finish', query: { id } });

      let id = row.id;
      // 已办
      let routeUrl: any = '';
      // 如果是同一个公司
      if (row.performer == UserUtil.getUser().id) {
        routeUrl = this.$router.resolve({ path: '/flow/finish', query: { id } });
        // 跳转 默认打开新窗口
        window.open(routeUrl.href, '_blank');
      } else {
        // 不同
        routeUrl =
          process.env.VUE_APP_SERVER_CODE +
          'auth/switchAccountByprocess?clientId=' +
          process.env.VUE_APP_CLIENT_ID +
          '&companyId=' +
          row.tenantId +
          '&id=' +
          id +
          '&token=' +
          TokenUtil.getToken() +
          '&seeType=' +
          'form' + '&frontType=3';
        // 跳转 默认打开新窗口
        window.open(routeUrl, '_blank');
      }
    }
  }

  // 格式化时间戳
  formatterDateTimestamp(val) {
    if (val) {
      return DateUtil.format(new Date(val), 'yyyy-MM-dd hh:mm:ss');
    }
  }

  /**
   * 获取列表数据
   */
  getTableData() {
    let params = {
      ...this.searchData,
      
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.homePage.getEnd(params).then(res => {
      if (res.code == '1') {
        if (res.data && res.data.list) {
          this.tableList = res.data.list;
          this.pageTotal = res.data.total;
        } else {
          this.tableList = [];
          this.pageTotal = 0;
        }
      } else {
        this.tableList = [];
        this.pageTotal = 0;
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 搜索
   */
  searchList() {
    this.pageIndex = 1;
    this.getTableData();
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize) {
    this.pageIndex = 1;
    this.pageSize = pageSize;
    this.getTableData();
  }

  /**
   * 改变页数
   */
  changePage(page) {
    this.pageIndex = page;
    this.getTableData();
  }
}
